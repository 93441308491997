<template>
  <div>
    <h6 v-if="!hideTitle" class="text-info">
      Linked Issues
    </h6>

    <b-list-group v-if="allIssues && allIssues.length > 0" flush>
      <b-list-group-item
        v-for="(issue, index) in allIssues" :key="index"
        class="flex-column align-items-start cursor-pointer list-item"
        @click.stop="issueModalView(issue.id, issueUpdated)"
        @click.middle.stop="issueModalView(issue.id, issueUpdated)"
      >
        <div class="d-flex w-100 justify-content-between">
          <h6>
            <span :class="`text-${resolveStatusColor(issue.status)}`">
              {{ issue.status.toUpperCase() }}
            </span>
            <span class="mx-75">:</span>
            <span class="text-primary">{{ issue.display_id }}</span>
            <span class="ml-50">
              [
              <feather-icon
                :icon="resolveClassificationIcon(issue.classification)"
                size="16"
              />
              {{ issue.classification }} ]
            </span>
          </h6>
          <div>
            <span
              class="py-25 px-50 border-rounded-2xl font-weight-bolder"
              :class="`text-${resolveSeverityColor(issue.severity)}`"
              style="border: 1px solid;"
            >
              {{ issue.severity.toUpperCase() }}
            </span>
          </div>
        </div>
        <p class="mt-50" v-sanitized-html="issue.name" />
      </b-list-group-item>
    </b-list-group>
    <div v-else class="px-2">
      <span class="text-muted font-small-4">
        No linked issues
      </span>
    </div>
  </div>
</template>

<script>
import { useRouter } from '@core/utils/utils'
import issueHelpers from '@/views/Issues/app/issueHelpers'

export default {
  name: 'ListIssues',
  props: {
    allIssues: {
      type: Array,
      default: () => [],
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()

    const {
      resolveClassificationIcon,
      resolveSeverityColor,
      resolveStatusColor,
    } = issueHelpers()

    const issueUpdated = () => context.emit('updated')

    const routeToIssue = issueId => {
      router.push({
        name: 'app_issues_focus',
        params: { id: issueId },
      })
    }

    const routeToIssueNewTab = issueId => {
      const routeData = router.resolve({
        name: 'app_issues_focus',
        params: { ...route.params, id: issueId },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      issueUpdated,
      routeToIssue,
      routeToIssueNewTab,
      resolveClassificationIcon,
      resolveSeverityColor,
      resolveStatusColor,
    }
  },
}
</script>

<style scoped lang="scss">
.list-item {
  border-left: 1px solid;
  border-right: 1px solid;
  border-radius: 1rem;
}
.list-item:not(:hover) {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
</style>
